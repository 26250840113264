import { useContext, useState } from "react";
import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { MUTATION_REQUEST_PHONE } from "../../../GQL";
import { useMutation } from "@apollo/client";

export interface PhoneButtonInterface {
	owner_id?: number | string;
	owner_name?: string;
	masked_phone?: string;
	owner_type?: "INMO" | "SUBSIDIARY";
	ui_style?: "masked" | "iconBtn" | "text";
	isWpp?: boolean;
	hasWpp?: boolean;
	property_info?: {
		id: number | string;
		type: "PROPERTY" | "PROJECT";
		link: string;
		title: string;
		operation_type: { id: number | string; name: string };
	};
}


export const usePhoneButton = ({
	owner_id,
	owner_name,
	masked_phone,
	owner_type = "INMO",
	ui_style = "masked",
	isWpp = false,
	property_info,
	hasWpp,
}: PhoneButtonInterface) => {
	const [reqPhoneMutation, { loading }] = useMutation(MUTATION_REQUEST_PHONE, {
		onError: _ => { },
	});

	const { main_domain, site_name } = useContext(ConfigStateContext);
	const [fullPhone, setFullPhone] = useState<string | null>(null);
	const [modalVisible, setModalVisible] = useState<boolean>(false);


	const requestFullPhone = async () => {
		let req = await reqPhoneMutation({
			variables: {
				id: owner_id,
				type: owner_type,
				isWpp: isWpp,
				context_id: property_info?.id,
				context_type: property_info?.type,
			},
		});
		let fullPhone = req?.data?.requestPhone_new;
		setFullPhone(fullPhone);
		if (fullPhone && !isWpp) setModalVisible(true);
		return req;
	};

	const wppMessage = ` Hola, ví esta propiedad en ${site_name} y me gustaría tener más información. ${property_info?.title} https://${main_domain}/${property_info?.link}`;

	return {
		owner_name,
		masked_phone,
		ui_style,
		isWpp,
		property_info,
		loading,
		fullPhone,
		modalVisible,
		wppMessage,
		hasWpp,
		requestFullPhone,
		setModalVisible,
	};
};
