import { CheckCircleTwoTone, PhoneOutlined } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";
import dynamic from "next/dynamic";
const Modal = dynamic(
	() => import("shared-components/Components/Modal/web").then(mod => mod.Modal),
	{ ssr: false }
);
import { useIsAuthModalShowing } from "../../../GlobalHooks/useIsAuthModalShowing";
import { useTheme } from "../../../Styles/ThemeHook";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { useContext } from "react";

export const ModalPhone = ({ visible = false, phone, name, onCancel, property_info = null }) => {
	const { isAuthModalShowing } = useIsAuthModalShowing();
	const { theme } = useTheme();
	const { translations, country_code } = useContext(ConfigStateContext);

	return (
		<Modal onClose={onCancel} show={visible && !isAuthModalShowing}>
			<Row
				justify="center"
				align="middle"
				gutter={[0, theme.spacing.xsSpacing]}
				style={{ textAlign: "center" }}>
				<Col span={24}>
					<CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: "25px" }} />
				</Col>
				<Col span={24}>
					<Typography.Text strong>
						{`¡No pierdas tiempo y ${translations.llama} ahora!`}
					</Typography.Text>
				</Col>
				{country_code !== "CO" && <Col span={24}>
					<Typography.Text strong>{name}</Typography.Text>
				</Col>}
				<Col span={24}>
					<a href={"tel:" + phone}>
						<Space size={theme.spacing.xsSpacing}>
							<PhoneOutlined style={{ color: theme.colors.linkColor }} />
							<Typography.Text style={{ color: theme.colors.linkColor }}>
								{phone}
							</Typography.Text>
						</Space>
					</a>
				</Col>
			</Row>
		</Modal>
	);
};

