import { Button, Typography } from "antd";
import { PhoneButtonInterface, usePhoneButton } from "./hook";
import { PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons";

import { ModalPhone } from "../ModalPhone/web";
import { useFacebookPixel } from "../../../GlobalHooks/web/FacebookPixel.hook";
import { useTheme } from "../../../Styles/ThemeHook";
import { LoadingIcon } from "shared-components/Components/CustomIcons/LoadingIcon/LoadingIcon";

const { Text } = Typography;

export const PhoneButton = (props: PhoneButtonInterface) => {
	const {
		masked_phone,
		owner_name,
		ui_style,
		isWpp,
		property_info,
		loading,
		fullPhone,
		modalVisible,
		wppMessage,
		requestFullPhone,
		setModalVisible,
	} = usePhoneButton(props);

	const { theme } = useTheme();

	const FP = useFacebookPixel();

	const clickHandler = () => {
		requestFullPhone().then(_ => {
			if (isWpp && fullPhone) {
				let url = `https://api.whatsapp.com/send?phone=${fullPhone}&text=${wppMessage}`;
				const categoryName: string = "Consultas Wpp";

				FP.StandardEvent(categoryName);
				window.open(url);
			} else if (!isWpp) {
				const categoryName: string = "Consultas Tel";
				FP.StandardEvent(categoryName);
			}
		});
	};

	const maskedStyle = (
		<>
			<Text className="masked_text">{masked_phone}</Text>
			<button
				onClick={clickHandler}
				className="btn-secondary"
			>
				{loading && <LoadingIcon classes="loading-icon" />}
				Ver teléfono
			</button>
		</>
	);

	const iconBtnStyle = (
		<Button
			htmlType="button"
			loading={loading}
			onClick={clickHandler}
			style={{ borderColor: theme.colors.linkColor }}
			icon={<PhoneOutlined style={{ color: theme.colors.linkColor }} />}
		/>
	);

	const iconBtnWppStyle = (
		<Button
			onClick={clickHandler}
			loading={loading}
			icon={<WhatsAppOutlined style={{ color: "#00C841" }} />}
			style={{ borderColor: "#00C841" }}
		/>
	);

	const textStyle = (
		<button
			onClick={clickHandler}
			className="btn-secondary"
		>
			{loading && <LoadingIcon classes="loading-icon" />}
			Ver teléfono
		</button>
	);

	const RenderComponentButton = () => {
		switch (ui_style) {
			case "iconBtn":
				return isWpp ? iconBtnWppStyle : iconBtnStyle;
			case "text":
				return textStyle;
			default:
				return maskedStyle;
		}
	};

	return (
		<>
			{modalVisible && (
				<ModalPhone
					visible={modalVisible}
					phone={fullPhone}
					name={owner_name}
					property_info={property_info}
					onCancel={() => {
						setModalVisible(false);
					}}
				/>
			)}
			<RenderComponentButton />
		</>
	);
};
