import "./styles.less";

import React, { FC } from "react";
import { Skeleton, Typography } from "antd";
import { DescriptionProps, useDescription } from "./Description.hook";

export const Description: FC<DescriptionProps> = props => {

	const {
		limitLength,
		hideInformation,
		expandable,
		description,
		showTitle,
		loading,
	} = useDescription(props);

	const ellipsis = hideInformation
		? { rows: limitLength, expandable: expandable, symbol: "Ver Más" }
		: false;

	return (
		<>
			{description && (
				<>
					{showTitle && <h4 className="subhead subhead-semibold high">Descripción</h4>}
					{loading ? (
						<Skeleton active />
					) : (
						<Typography.Paragraph
							className={"property-description body body-regular body-1 high"}
							ellipsis={ellipsis}>
							<span
								dangerouslySetInnerHTML={{
									__html: description,
								}}
							/>
						</Typography.Paragraph>
					)}
				</>
			)}
		</>
	);
};
