import { Footer } from "../../../shared-components/ViewFragments/Footer/web";
//import { Header } from "../../../shared-components/ViewFragments/Header/web";
import { Layout } from "antd";
import { PicHomeFooter } from "../../../shared-components/ViewFragments/Footer/PicHomeFooter/web";
import React from "react";
import { useRouter } from "next/router";
import { useTheme } from "../../../shared-components/Styles/ThemeHook";
import Header from "RefactorSearchPage/Header/web";

const { Content } = Layout;

interface propsLayout {
	children;
	classContainer?: string;
	withFooter?: boolean;
}

export const PageLayout = ({ classContainer = "", children, withFooter = true }: propsLayout) => {
	const { theme } = useTheme();

	const router = useRouter();
	const path = router.asPath;
	const arrayPages = path.slice(1, path.length).split("/");
	const page = arrayPages.length === 1 ? arrayPages[0] : arrayPages[arrayPages.length - 2];
	const classLayout = `${page}-page-container`;

	return (
		<Layout
			className={classLayout}
			style={{
				background: theme.colors.backgroundColor,
			}}>
			<Header showSearchBar={false} showArrow={false} />
			<Content className={classContainer != "" && classContainer}>{children}</Content>

			{withFooter && <Footer size={"large"} />}
			<PicHomeFooter />

			<style jsx global>{`
				.${classLayout} .header_container .header {
					border-bottom: 1px solid ${theme.colors.borderColor};
				}

				.${classLayout} .ant-layout-content {
					min-height: calc(100vh - 450px);
				}
			`}</style>
		</Layout>
	);
};
